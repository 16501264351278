import './GalleryBlock.scss';
import Marquee from 'react-fast-marquee';
import galleryData from './gallery-data';

const GalleryBlock = () => {
    return (
        <section className='gallery-block'>
            <Marquee style={{overflowY: 'hidden'}}>
                <div className='gallery-block-image-container'>
                    {
                        galleryData.map((img, index) => (
                            <img src={img} alt={'gallery'} key={index}/>
                        ))
                    }
                </div>

                <div className='gallery-block-image-container'>
                    {
                        galleryData.map((img, index) => (
                            <img src={img} alt={'gallery'} key={index}/>
                        ))
                    }
                </div>
            </Marquee>

            <div className='gallery-block-text-container'>
                <div className='gallery-block-title'>
                    <span>найочікуваніша подія курсу</span>
                    <h3>Випускний MOPIS SCHOOL</h3>
                </div>

                <span className='gallery-block-description'>Це подія, яка об'єднує людей, закоханих у весілля, дає нові можливості та стимул до розвитку. Кожного разу – нова та унікальна концепція, продумана до найменших деталей, як ми завжди робимо в Mopis.</span>
            </div>
        </section>
    )
}

export default GalleryBlock
