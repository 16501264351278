import GALLERY_1 from '../../images/gallery/gallery-1.webp';
import GALLERY_2 from '../../images/gallery/gallery-2.webp';
import GALLERY_3 from '../../images/gallery/gallery-3.webp';
import GALLERY_4 from '../../images/gallery/gallery-4.webp';
import GALLERY_5 from '../../images/gallery/gallery-5.webp';
import GALLERY_6 from '../../images/gallery/gallery-6.webp';
import GALLERY_7 from '../../images/gallery/gallery-7.webp';
import GALLERY_8 from '../../images/gallery/gallery-8.webp';
import GALLERY_9 from '../../images/gallery/gallery-9.webp';

const galleryData = [
    GALLERY_1,
    GALLERY_2,
    GALLERY_3,
    GALLERY_4,
    GALLERY_5,
    GALLERY_6,
    GALLERY_7,
    GALLERY_8,
    GALLERY_9
];

export default galleryData;